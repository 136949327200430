import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  InputAdornment, TextField
} from "@mui/material";
import { OpenEye, CloseEye, AqsatLogo } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
  configJSON,
} from "./EmailAccountRegistrationController";
// Customizable Area Start

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  userPass: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/\d/, 'Password must contain at least one number')
    .required('Password is required').trim(),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('userPass'), null], "Passwords don't match")
    .required('Password is required').trim(),
});

const initialValues = {
  userPass: '',
  confirmPass: ''
};


const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: "28px",
      },
      h6: {
        fontWeight: 500,
      },

      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End


export default class CreateNewPassword extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { passwordVisible, passwordVisible2 } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <div id="main_block">
        <ThemeProvider theme={theme}>
          <Box>
            <Box component={'div'} sx={Styles.imageWrapper} >
              <img src={AqsatLogo} alt="Aqsat_logo" onClick={() => this.handleNavigation('Home')} style={{cursor: 'pointer'}} data-test-id="homeLogoBtn"/>
            </Box>
            <Container maxWidth="xl" sx={Styles.mainDivStyle}>
              <Typography
                variant="h4"
                gutterBottom
                sx={Styles.HeadingStyle}
              >
                Create your password
              </Typography>
              <Formik
                validationSchema={validationSchema}
                data-test-id="address-dialog"
                initialValues={initialValues}
                onSubmit={(values) => this.handleFinalSubmit(values)}
              >
                {({
                  values,
                  touched,
                  errors,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  isValid,
                }) => {
                  return (
                    <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit}>
                      <Field
                        as={TextField}
                        variant="standard"
                        type={passwordVisible ? "text" : "password"}
                        sx={Styles.PhoneInput}
                        placeholder="Enter Password"
                        name="userPass"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={passwordVisible ? OpenEye : CloseEye} alt="eye_icon" style={Styles.phoneIconStyle} onClick={() => this.setState({ passwordVisible: !passwordVisible })} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.userPass}
                        error={touched.userPass && Boolean(errors.userPass)}
                        helperText={touched.userPass && errors.userPass}
                      />
                      <Field
                        as={TextField}
                        variant="standard"
                        type={passwordVisible2 ? "text" : "password"}
                        sx={Styles.PhoneInputSecond}
                        placeholder="Confirm Password"
                        name="confirmPass"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src={passwordVisible2 ? OpenEye : CloseEye} alt="eye_icon" style={Styles.phoneIconStyle} onClick={() => this.setState({ passwordVisible2: !passwordVisible2 })} />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPass}
                        error={touched.confirmPass && Boolean(errors.confirmPass)}
                        helperText={touched.confirmPass && errors.confirmPass}
                      />
                      <Button data-test-id="submitBtn" type="submit" sx={Styles.NextButton}>
                        {configJSON.nextButton}
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </Container>
          </Box>
        </ThemeProvider>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

const Styles = {
  mainDivStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "@media (max-width:600px)": {
      marginTop: "63px",
    },
    "@media (min-width:600px)": {
      marginTop: "58px",
    },
    "@media (min-width:1280px)": {
      marginTop: "150px",
    },
  },
  centerContent: {
    textAlign: "center",
  },
  imageWrapper: {
    marginTop: '30px',
    marginBottom: '25px',
    paddingLeft: '16px',
    display: 'flex',
    "@media (max-width: 600px)": {
      marginTop: '20px',
      paddingLeft: 'auto',
    },
    "@media (min-width: 600px)": {
      marginTop: '25px',
      justifyContent: 'center',
    },
    "@media (min-width: 1280px)": {
      marginTop: '30px',
      justifyContent: 'flex-start',
      paddingLeft: '99px',
    },
    "@media (min-width: 1668px)": {
      marginTop: '30px',
      justifyContent: 'flex-start',
      paddingLeft: '226px',
    },
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
    marginBottom: "67px",
    fontFamily: "Poppins",
    lineHeight: "29.33px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      marginBottom: "87px",
    },
    "@media (min-width: 600px)": {
      marginBottom: "69px",
    },
    "@media (min-width: 1280px)": {
      marginBottom: "68px",
    },
  },

  PhoneInput: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    width: "100%",
    height: "44px",
    marginBottom: "23px",
    backgroundColor: "transparent",
    "@media (max-width:600px)": {
      width: '100%',
      marginBottom: "24px",
    },
    "@media (min-width:600px)": {
      width: '100%',
      marginBottom: "56px",
    },
    "@media (min-width:1668px)": {
      width: '100%',
      marginBottom: "23px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#6200EA',
    },

    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #CBD5E1',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6200EA',
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#6200EA',
      },
    },

  },
  PhoneInputSecond: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    width: "100%",
    height: "44px",
    marginBottom: "23px",
    backgroundColor: "transparent",
    "@media (max-width:600px)": {
      width: '100%',
      marginBottom: "24px",
    },
    "@media (min-width:600px)": {
      width: '100%',
      marginBottom: "38px",
    },
    "@media (min-width:1280px)": {
      width: '100%',
      marginBottom: "56px",
    },
    "@media (min-width:1668px)": {
      width: '100%',
      marginBottom: "23px",
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#6200EA',
    },

    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #CBD5E1',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#6200EA',
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#6200EA',
      },
    },

  },
  phoneIconStyle: {
    marginRight: "8px",
    cursor: 'pointer'
  },
  NextButton: {
    fontFamily: "Poppins",
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    width: "520px",
    height: "53px",
    padding: "19.5px 236px",
    borderRadius: "6px",
    backgroundColor: "#1D36BB",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      width: "343px",
      height: "44px",
      padding: "18px 51px",
      marginTop: '23px'
    },
    "&:hover": {
      backgroundColor: "#1D36BB",
      color: "#fff",
      boxShadow: "none",
    },
  },
}
// Customizable Area End